import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a08fdb28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between p-mt-4 p-mx-3 f-size-14" }
const _hoisted_2 = { class: "p-d-flex w-100" }
const _hoisted_3 = { class: "p-text-left mr-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "pointer" }
const _hoisted_6 = { class: "w-100 p-text-right" }
const _hoisted_7 = { class: "product-list p-grid p-mt-1" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "product-image" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "product-name p-mt-2 line-clamp p-text-bold p-text-left p-ml-1" }
const _hoisted_12 = { class: "line-clamp w-100 mb-1 text-description p-text-left p-ml-1 p-mb-2" }
const _hoisted_13 = { class: "p-ml-1 p-mt-auto p-mb-1" }
const _hoisted_14 = { class: "p-d-flex p-flex-wrap" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  for: "",
  class: "p-my-0 p-text-bold"
}
const _hoisted_17 = {
  key: 1,
  class: "p-d-flex p-flex-wrap"
}
const _hoisted_18 = { class: "text-secondary line-through f-size-14 product-real-price" }
const _hoisted_19 = { class: "p-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfiniteLoading = _resolveComponent("InfiniteLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumb, (item, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              onClick: ($event: any) => (_ctx.handleBreadcrumb(item)),
              class: ""
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1),
              (index !== _ctx.breadcrumb.length - 1)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" / ")
                  ], 64))
                : _createCommentVNode("", true)
            ], 8, _hoisted_4))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, "แสดงผล " + _toDisplayString(_ctx.productCount) + " รายการ", 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, pindex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: pindex,
          class: "p-col-6"
        }, [
          _createElementVNode("div", {
            class: "p-px-3 product-card",
            onClick: ($event: any) => (_ctx.onRouteToProductDetail(product.id))
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: product.imageUrl,
                  alt: "",
                  class: "lazy-image"
                }, null, 8, _hoisted_10)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", null, _toDisplayString(product.name), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", null, _toDisplayString(product.shortDescription), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (product.promotionPrice == 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, "฿" + _toDisplayString(_ctx.$filters.formatNumber(product.price)), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("span", {
                        for: "",
                        class: "my-0 p-text-bold mr-1",
                        style: _normalizeStyle({ color: _ctx.brand.colorThemeMain })
                      }, "฿" + _toDisplayString(_ctx.$filters.formatNumber(product.promotionPrice)), 5),
                      _createElementVNode("span", _hoisted_18, "฿" + _toDisplayString(_ctx.$filters.formatNumber(product.price)), 1)
                    ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_Button, {
                class: "w-100",
                label: "ดูรายละเอียด",
                style: _normalizeStyle({
              'background-color': _ctx.brand.colorThemeMain,
              'border-color': _ctx.brand.colorThemeMain,
            })
              }, null, 8, ["style"])
            ])
          ], 8, _hoisted_8)
        ]))
      }), 128))
    ]),
    _createVNode(_component_InfiniteLoading, {
      onInfiniteHandler: _ctx.infiniteHandler,
      class: "p-mt-4"
    }, null, 8, ["onInfiniteHandler"])
  ], 64))
}